.chart-token-menu-items.menu-items {
    width: 15.8rem;
    right: unset;
    left: 0;
}
.menu-items:focus-visible {
    border: 1px solid #262638;
  }
.menu-items {
    position: absolute;
    right: 0;
    top: 4.3rem;
    min-width: 15.5rem;
    width: 100%;
    transform-origin: top right;
    border-radius: 0.4rem;
    background: #16182D;
    border: 1px solid #32344c;
    list-style: none;
    cursor: pointer;
    outline: none;
    z-index: 1000;
  }
  .menu-item {
    display: flex !important;
    align-items: center;
    font-size: 1.5rem;
    color: #a0a3c4;
    padding-bottom: 1.5rem;
    font-size: 1.4rem;
    padding: 0.85rem 0.8rem;
    /* border: 1px solid transparent; */
    border-radius: 0.4rem;
  }
  .menu-item:hover {
    background: #808aff14 !important;
    /* border: 1px solid #4275a8; */
    border-radius: 0.4rem;
    opacity: 1;
    color: #eee;
  }
  .menu-item > p {
    margin: 0px;
    padding-left: 1rem;
  }
  .menu-item > a {
    display: inline-flex;
  }
  