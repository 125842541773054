.connectBtnBox,
.mintBtnBox,
.betBtnBox,
.stakeBtnBox,
.stakeBtnBox2,
.stakeBtnBox3,
.claimBtnBox,
.unstakeBtnBox {
  .MuiButton-root {
    max-width: 100%;
    color: #ffffff;
  }
}

.connectBtnBox,
.mintBtnBox,
.stakeBtnBox,
.stakeBtnBox2 {
  .MuiButton-root {
    font-size: 16px;
    font-weight: 700;
  }
}

.connectBtnBox,
.betBtnBox,
.stakeBtnBox3,
.claimBtnBox,
.unstakeBtnBox {
  .MuiButton-root {
    height: 40px;
  }
}

.mintBtnBox,
.betBtnBox,
.stakeBtnBox,
.stakeBtnBox2 {
  .MuiButton-root {
    border-radius: 8px;
  }
}

.betBtnBox {
  .MuiButton-root {
    font-size: 12px;
    font-weight: 500;
  }
}
.claimBtnBox,
.stakeBtnBox3,
.unstakeBtnBox {
  .MuiButton-root {
    font-size: 12px;
    font-weight: 700;
    border-radius: 10px;
  }
}

.stakeBtnBox3,
.unstakeBtnBox {
  .MuiButton-root {
    width: 182px;
  }
}

.claimBtnBox {
  .MuiButton-root {
    width: 110px;
  }
}

.betBtnBox,
.stakeBtnBox,
.stakeBtnBox2 {
  .MuiButton-root {
    width: 80px;
  }
}

.connectBtnBox {
  .MuiButton-root {
    border-radius: 4px;
  }

  .MuiButton-contained {
    background: linear-gradient(180deg, #3c66e8 0%, #5e3ce8 100%);
    border: 2px solid #2c53cd;
  }
}

.mintBtnBox {
  .MuiButton-root {
    max-height: 60px;
    height: 60px;
    width: 262px;
  }

  .MuiButton-contained {
    background: linear-gradient(122.33deg, #0a4ad5 7.22%, #7a00cb 92.99%);
  }
}

.betBtnBox {
  .MuiButton-contained {
    background: linear-gradient(180deg, #0026f0 0%, #8e0baf 100%);
  }
}

.stakeBtnBox {
  .MuiButton-contained {
    background: linear-gradient(122.33deg, #0aa4d5 7.22%, #7a00cb 92.99%);
  }
}

.stakeBtnBox2 {
  .MuiButton-contained {
    background: linear-gradient(122.33deg, #0a4ad5 7.22%, #7a00cb 92.99%);
  }
}

.stakeBtnBox3,
.claimBtnBox {
  .MuiButton-contained {
    background-color: #f89542;
    &:hover {
      background: #f89542;
    }
  }
}

.unstakeBtnBox {
  .MuiButton-contained {
    background-color: #8a85e7;
    &:hover {
      background: #8a85e7;
    }
  }
}

@media only screen and (max-width: 1080px) {
  .mintBtnBox {
    width: 100%;
    .MuiButton-root {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1080px) {
  .claimBtnBox,
  .unstakeBtnBox {
    width: 100%;
    .MuiButton-root {
      width: 100%;
    }
  }

  .connectBtnBox {
    .MuiButton-root {
      font-size: 14px;
    }
  }
}
