.header-view {
  width: 100%;
  height: 100px;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.0001) 100%);

  .header {
    height: 100%;

    .logo {
      margin-left: 28px;
    }

    .line {
      width: 1px;
      height: 36px;
      background: rgba(255, 255, 255, 0.16);
    }

    .staked {
      width: 160px;
      height: 60px;
      border: 1px solid rgba(255, 255, 255, 0.16);
    }

    .menuList {
      margin-left: 30px;

      .menuItem {
        margin-left: 42px;
        color: rgb(255, 255, 255, 0.25);

        &:hover {
          cursor: pointer;
          color: #eef2f9;
        }
      }

      .menuItemActive {
        color: #eef2f9;
      }
    }

    .connectBtnBox {
      margin-right: 42px;
    }

    .links {
      height: 62px;
      background: rgba(18, 7, 53, 0.2);
      border: 1px solid #282562;
      border-radius: 53px;
      padding: 0 32px;
    }
  }
}

@media only screen and (max-width: 1080px) {
  .header-view {
    .header {
      height: 100%;

      .logo {
        margin-left: 18px;
      }
      .staked {
        width: 90px;
        height: 44px;
      }
      .connectBtnBox {
        margin-right: 16px;
        .MuiButton-root {
          padding: 6px;
        }
      }
    }
  }
}

@media only screen and (max-width: 321px) {
  .header-view {
    .header {
      .logo {
        margin-left: 16px;
      }
      .connectBtnBox {
        margin-right: 12px;
      }
    }
  }
}
