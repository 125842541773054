* {
  outline: none;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none !important;
  }
}

html,
body,
#root {
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  box-sizing: border-box;
}

#root {
  position: relative;
}

.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  z-index: 1;
  background-size: cover;
  font-family: "Poppins";
  color: #ffffff;
  transition: all ease 0.33ms;
}

.MuiButtonBase-root {
  display: flex;
  align-items: center;
}

.container {
  width: 1440px;
  max-width: 100%;
}

.flex-1 {
  flex: 1;
}

.ml-4 {
  margin-left: 4px;
}

.ml-6 {
  margin-left: 6px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-14 {
  margin-left: 14px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-24 {
  margin-left: 24px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-32 {
  margin-left: 32px;
}

.ml-36 {
  margin-left: 36px;
}

.ml-42 {
  margin-left: 42px;
}

.ml-44 {
  margin-left: 44px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-26 {
  margin-top: 26px;
}

.mt-28 {
  margin-top: 28px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-36 {
  margin-top: 36px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-48 {
  margin-top: 48px;
}

.font-10 {
  font-size: 10px;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-24 {
  font-size: 24px;
}

.font-32 {
  font-size: 32px;
}

.font-36 {
  font-size: 30px;
}

.font-40 {
  font-size: 40px;
}

.font-weight-b {
  font-family: "Poppins-ExtraBold";
}

.font-weight-6 {
  font-family: "Poppins-Bold";
}

.font-weight-5 {
  font-family: "Poppins-Medium";
}

.font-weight-4 {
  font-family: "Poppins";
}

.font-weight-3 {
  font-family: "Poppins-Light";
}

.font-family-Hi-Jack {
  font-family: "Hi Jack";
}

.ml-12 {
  margin-left: 12px;
}

.mt-10 {
  margin-top: 10px;
}

.font-12 {
  font-size: 12px;
}

.color1 {
  color: #ffffff;
}

.color2 {
  color: rgb(255, 255, 255, 0.1);
}

.color3 {
  color: rgba(238, 242, 249, 0.5);
}

.color4 {
  color: rgba(255, 255, 255, 0.5);
}

.color5 {
  color: #ffd100;
}

.color6 {
  color: #fca149;
}

.color7 {
  color: #777779;
}

.color8 {
  color: #eef2f9;
}

.color9 {
  color: #9b96ff;
}

.color10 {
  color: #a4fc5d;
}

a {
  text-decoration: none;
}

.underline {
  text-decoration-line: underline;
}

.pointer {
  cursor: pointer;
}

.MuiLink-root.active {
  text-decoration: none !important;
}

img {
  max-width: 100%;
  cursor: pointer;
}

.word-break {
  word-break: break-word;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.border-radius-8 {
  border-radius: 8px;
}

.app-container {
  position: relative;
  .header-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100px;
    z-index: 99;
  }
}
