.dialogBg {
  width: 712px;
  max-width: 100%;
  line-height: 20px;
  .line-height-24 {
    line-height: 24px;
  }

  .dialogHeader {
    padding: 36px 46px;
    border-bottom: 1px solid #20253e;
  }

  .networkList {
    margin: 20px 24px;

    .networkItem {
      padding: 0 12px;
      height: 48px;
      cursor: pointer;

      &:not(:first-child) {
        margin-top: 8px;
      }

      .isConnected {
        .connected {
          margin-left: 8px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #5ad769;
        }
      }
    }

    .networkItemActive {
      background: #20253e;
      border-radius: 6px;
    }
  }

  .stakeInfo {
    padding: 18px 40px 20px;

    .liveInfo {
      padding: 16px;
      background: rgba(255, 255, 255, 0.08);
      border-radius: 4px;
    }

    .selectBg {
      width: 320px;
      max-width: 100%;
      height: 44px;
      padding-left: 24px;
      padding-right: 10px;
      background: #20253e;
      border-radius: 10px;
    }
    .bg {
      background: #000000;
      padding: 8px;
    }
    .stakeItemBg {
      position: relative;
    }
    .stakeItemAcitve {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
    }
    .stakeItem {
      border-radius: 10px;
      background: #20253e;
    }
    .finalBtn {
      width: 138px;
      height: 24px;
      background: #191818;
      border-radius: 4px;
    }
    .stakeList {
      grid-template-columns: repeat(3, 200px);
      grid-gap: 8px;
      .stakeItem {
        .stakeItem-t {
          background: #18415d;
          height: 200px;
          border-radius: 8px 8px 0 0;
          position: relative;
          .tier {
            width: 100%;
            height: 28px;
            padding-left: 8px;
            padding-right: 4px;
            background: rgba(0, 0, 0, 0.16);
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 9;
          }
        }
        .stakeItem-b,
        .stakeItem-b2 {
          border-radius: 0 0 8px 8px;
          padding: 0 8px;
        }

        .stakeItem-b {
          height: 37px;
        }

        .stakeItem-b2 {
          height: 68px;
        }
      }
    }
  }
}
.MuiDialog-paperWidthSm {
  max-width: 712px !important;
}
.MuiDialog-paper {
  background-color: #16182d;
  margin: 0 !important;
  border-radius: 10px;

  &::-webkit-scrollbar {
    width: 0;
  }
}

@media only screen and (max-width: 680px) {
  .dialogBg {
    width: 600px;
    .dialogHeader {
      padding: 36px 24px;
    }

    .stakeInfo {
      padding: 18px 20px 20px;
      .liveInfo {
        padding: 16px 16px 20px;
      }

      .stakeItemBg {
        width: 264px;
      }
      .finalBtn {
        width: 100%;
      }

      .stakeList {
        grid-template-columns: repeat(1, 200px);
        grid-gap: 8px;
        .stakeItem {
          height: 100px;
          width: 100%;

          .stakeItem-l {
            height: 100%;
            width: 100px;
            background: #18415d;
            border-radius: 8px 0 0 8px;
          }
          .stakeItem-r {
            padding: 8px;
            border-radius: 0 8px 8px 0;
          }
        }
      }
    }
  }
}
